.sales-slider :global(.swiper-button-next),.sales-slider :global(.swiper-button-prev){
  background: transparent;
  box-shadow: none;
  box-sizing: border-box;
  display: inline-block;
  padding: 0;
}



.sales-slider :global(.swiper-button-prev),.sales-slider :global(.swiper-button-next) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px !important;
  height: 15px !important;
}


.sales-slider :global(.swiper-button-prev:hover:after),.sales-slider :global(.swiper-button-next:hover:after) {
  border-color: rgba(0, 0, 0, 0.7);

}

.sales-slider :global(.swiper-button-prev:after),.sales-slider :global(.swiper-button-next:after) {
  content: '' !important;
  border-width: 3px;
  border-color: rgba(0, 0, 0, 0.2);
  transition: all .3s ease;
  width: 13px;
  height: 13px;
  display: inline-block;
  border-style: solid;
  border-bottom: none;
}

.sales-slider :global(.swiper-button-next:after){
  border-right: none;
  transform: rotate(-45deg);
}


.sales-slider :global(.swiper-button-prev:after){
  border-left: none;
  transform: rotate(45deg);
}




